import React, { useState, useEffect, useRef, FC, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import routes from "../routes";
import { Link } from 'react-router-dom';
import './DefaultHeader.scss';

/*-- Import dependencies --*/
import LoadingScreen from '../components/common/loadingScreen/LoadingScreen';
  
/**-- Imports for redux --*/
import { useSelector } from 'react-redux';
import { RootState, useReduxDispatch } from '../redux/store';

import { showMessageBox } from '../redux/messageBox/messageBoxAction';
import { addAzureConfiguration } from '../redux/azureConfiguration/AzureConfiguration_Actions';
import { addUserSession } from '../redux/userSession/UserSession_Actions';
import { addGrantedMenu } from '../redux/userSession/UserSession_Actions';
import { addGrantedApps } from '../redux/userSession/UserSession_Actions';
import { resetUserSession } from '../redux/userSession/UserSession_Actions';

/*-- Import dependencies --*/
import { ErrorTypes } from '../utilities/settings';
import logo from '../assets/images/logo/KeellsGreenLogo.png';
import userImg from '../assets/images/avatars/user.jpg';
import { post, get } from '../utilities/apiClient';
import { IconContext } from 'react-icons';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { LOGIN_URL } from '../utilities/environment_config';
import Sidebar from './Sidebar';
import { CBadge, CNavbarBrand, CSidebarToggler } from '@coreui/react';
import { Badge, Container } from 'reactstrap';
import DefaultBreadcrumb from './DefaultBreadcrumb';

const  DefaultHeader: FC = () => {
    const dispatch = useReduxDispatch();
    const userSessionData = useSelector((state: RootState) => state.UserSession);

    let navMenuNode: any = useRef(null);
    let userMenuNode: any = useRef(null);
    let grantedAppMenuNode: any = useRef(null);

    const [menuList, setMenuList]: any = useState([]);
    const [isMenuLoaded, setIsMenuLoaded] = useState(false);
    const [navMenuVisible, setNavMenuVisible] = useState(true);
    const [userMenuVisible, setUserMenuVisible] = useState(true);
    const [grantedAppMenuVisible, setGrantedAppMenuVisible] = useState(true);
    const [currentRoutePath, setCurrentRoutePath] = useState("");
    const azureConfig = useSelector((state: RootState) => state.AzureConfiguration);
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    useEffect(() => {
        let t1 = menuList
        checkMenuFunctions();
        // if ((azureConfig.blobStorageAccount == "") || (azureConfig.blobStorageBaseUrl == "") || (azureConfig.blobStorageSASUrl == "")) {
        //     getAzureConfiguration();
        // }
        // add when mounted
        const handleResize = () => {
            setNavMenuVisible(window.innerWidth > 768);
          };
      
          // Attach event listener for window resize
          window.addEventListener('resize', handleResize);
       document.addEventListener("mousedown", handleNavMenuOutsideClick);
       document.addEventListener("mousedown", handleUserMenuOutsideClick);
       document.addEventListener("mousedown", handleGrantedAppMenuOutsideClick);

        // return function to be called when unmounted
        return () => {
           document.removeEventListener("mousedown", handleNavMenuOutsideClick);
           document.removeEventListener("mousedown", handleUserMenuOutsideClick);
           document.removeEventListener("mousedown", handleGrantedAppMenuOutsideClick);
        };

    }, []);

        

    function loadingScreen() {
        return <LoadingScreen show={true} />
    }

    const checkMenuFunctions = () => {
        if (localStorage.getItem("currentUser") == null || localStorage.getItem("mainMenu") == null || (localStorage.getItem("mainMenu") == "undefined")) 
        {
            get("/Permission/GetCurrentSession", {}, true)
            .then((response: any) => {
                if (response.status === 200) {
                    if (response.data != null) {
                        dispatch(addUserSession(response.data))
                        var currentSession = response.data;
                        localStorage.setItem("currentUser", JSON.stringify(currentSession));
                        get("/Permission/GetMainMenu?roleList=" + response.data.userRoles + "&isMobileRequest=0", {}, true)
                            .then((response: any) => {
                                if (response.data.statusCode === 200) {
                                    if (response.data.result != null) {
                                        localStorage.setItem("mainMenu", JSON.stringify(response.data.result.menu));
                                        dispatch(addGrantedMenu(response.data.result.menu))
                                        setMenuList(response.data.result.menu);
                                        setIsMenuLoaded(true);
                                        //getApplicationList();
                                        //getAzureConfiguration();
                                    }
                                } else {
                                    let errorType = response.data.errorList[0].errorType;
                                    let messageBox = {
                                        show: true,
                                        title: ErrorTypes.Error === errorType ? "Error" : ErrorTypes.Warning === errorType ? "Warning" : "Information",
                                        className: ErrorTypes.Error === errorType ? "error" : ErrorTypes.Warning === errorType ? "warning" : "info",
                                        content: response.data.errorList[0].statusMessage,
                                        isConfirmation: false,
                                        callBackFunction: null
                                    }
                                    dispatch(showMessageBox(messageBox));
                                }
                            })
                    }
                } else {
                    let errorType = response.data.errorList[0].errorType;
                    let messageBox = {
                        show: true,
                        title: ErrorTypes.Error === errorType ? "Error" : ErrorTypes.Warning === errorType ? "Warning" : "Information",
                        className: ErrorTypes.Error === errorType ? "error" : ErrorTypes.Warning === errorType ? "warning" : "info",
                        content: response.data.errorList[0].statusMessage,
                        isConfirmation: false,
                        callBackFunction: null
                    }
                    dispatch(showMessageBox(messageBox));
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                    window.location.replace(LOGIN_URL);
                }
            })
        }
        else {
            setIsMenuLoaded(false);
            var t = userSessionData.userId
        //      if (userSessionData.userId == 0) {
        //     window.location.replace(LOGIN_URL);
        // }
        }
    };

    const onLogout = () => {
        let sessionData = {
            UserID: userSessionData.userId,
            UserName: userSessionData.userName,
            Remark: 'logout by user',
            LogoutType: 1,
            LogoutBy: userSessionData.userId
        }
        localStorage.removeItem("currentUser");
        dispatch(resetUserSession());
        post('/User/UserSessionLogout', sessionData, true).then((response: any) => {
            if (response.data.statusCode === 200) {
                dispatch(resetUserSession());
                localStorage.removeItem("mainMenu");
                localStorage.removeItem("currentUser");
                window.location.replace(LOGIN_URL);
            } else {
                let errorType = response.data.errorList[0].errorType;
                let messageBox = {
                    show: true,
                    title: ErrorTypes.Error === errorType ? "Error" : ErrorTypes.Warning === errorType ? "Warning" : "Information",
                    className: ErrorTypes.Error === errorType ? "error" : ErrorTypes.Warning === errorType ? "warning" : "info",
                    content: response.data.errorList[0].statusMessage,
                    isConfirmation: false,
                    callBackFunction: null
                }
                dispatch(showMessageBox(messageBox));
            }
        });
    };

    const changePassword = () => {
        //Redirect to user management password change page
        // window.location.replace(LOGIN_URL + '/ChangePasswordByAdmin');
    };

    const getApplicationList = () => {
        get(`/User/GetUserApplicationClientByUserID?userID=${userSessionData.userId}`, {}, true).then((response: any) => {
            if (response.data.statusCode === 200) {
                if (response.data.result != null) {
                    dispatch(addGrantedApps(response.data.result));
                }
            } else {
                let errorType = response.data.errorList[0].errorType;
                let messageBox = {
                    show: true,
                    title: ErrorTypes.Error === errorType ? "Error" : ErrorTypes.Warning === errorType ? "Warning" : "Information",
                    className: ErrorTypes.Error === errorType ? "error" : ErrorTypes.Warning === errorType ? "warning" : "info",
                    content: response.data.errorList[0].statusMessage,
                    isConfirmation: false,
                    callBackFunction: null
                }
                dispatch(showMessageBox(messageBox));
            }
        })
    };

    const getAzureConfiguration = () => {
        get(`/Common/GetAzureConfiguration`, {}, true).then((response: any) => {
            if (response.data.statusCode === 200) {
                if (response.data.result != null) {
                    dispatch(addAzureConfiguration(response.data.result));
                }
            } else {
                let errorType = response.data.errorList[0].errorType;
                let messageBox = {
                    show: true,
                    title: ErrorTypes.Error === errorType ? "Error" : ErrorTypes.Warning === errorType ? "Warning" : "Information",
                    className: ErrorTypes.Error === errorType ? "error" : ErrorTypes.Warning === errorType ? "warning" : "info",
                    content: response.data.errorList[0].statusMessage,
                    isConfirmation: false,
                    callBackFunction: null
                }
                dispatch(showMessageBox(messageBox));
            }
        })
    };

    const openApplication = (baseURL: string) => {
        if ((window.location.host) === baseURL.split('/')[2]) {
            window.location.replace(baseURL)
        } else {
            window.open(baseURL)
        }
    };

    const handleNavMenuOutsideClick = (event: any) => {
        if (event !== null && event.target !== null && event.target.id !== null) {
            if (event.target.id === "main_menu_toggler_icon") {
                setNavMenuVisible(prevState => !prevState);
            } else {
                if (navMenuNode && navMenuNode.current) {
                    if (navMenuNode.current.contains(event.target)) {
                        return;
                    }
                    setNavMenuVisible(false);
                }
            }
        }
    };

    useEffect(() => {
        if (!navMenuVisible) {
            let sideMenu = document.getElementById("default_sidebar_id");
            sideMenu != null && sideMenu.classList.replace('sidebar-menu-close', 'sidebar-menu-open');
        } else {
            let sideMenu = document.getElementById("default_sidebar_id");
            sideMenu != null && sideMenu.classList.replace('sidebar-menu-open', 'sidebar-menu-close');
        }
    }, [navMenuVisible]);

    const handleGrantedAppMenuOutsideClick = (event: any) => {
        if (event !== null && event.target !== null && event.target.id !== null) {
            if (event.target.id === "granted_app_list_toggler_id") {
                setGrantedAppMenuVisible(prevState => !prevState);
            } else {
                if (grantedAppMenuNode && grantedAppMenuNode.current) {
                    if (grantedAppMenuNode.current.contains(event.target)) {
                        return;
                    }
                    setGrantedAppMenuVisible(true);
                }
            }
        }
    };

    useEffect(() => {
        if (!grantedAppMenuVisible) {
            let appMenu = document.getElementById("default_granted_app_menu_id");
            if (appMenu != null) {
                appMenu.style.display = "block";
            }
        } else {
            let appMenu = document.getElementById("default_granted_app_menu_id");
            if (appMenu != null) {
                appMenu.style.display = "none";
            }
        }
    }, [grantedAppMenuVisible]);

    const handleUserMenuOutsideClick = (event: any) => {
        if (event !== null && event.target !== null && event.target.id !== null) {
            if (event.target.id === "user_menu_toggler_id") {
                setUserMenuVisible(prevState => !prevState);
            } 
            // else {
            //     if (userMenuNode && userMenuNode.current) {
            //         if (userMenuNode.current.contains(event.target)) {
            //             return;
            //         }
            //         setUserMenuVisible(true);
            //     }
            // }
        }
    };

    useEffect(() => {
        if (!userMenuVisible) {
            let userPopup = document.getElementById("default_user_menu_id");
            if (userPopup != null) {
                userPopup.style.display = "block";
            }
        } else {
            let userPopup = document.getElementById("default_user_menu_id");
            if (userPopup != null) {
                userPopup.style.display = "none";
            }
        }
    }, [userMenuVisible]);

    const setBreadcrumb = (routePath: string) => {
        setCurrentRoutePath(routePath);
    };

    const resetBreadcrumb = () => {
        setCurrentRoutePath("");
    };

    interface SideBarProps {
        isVisible: boolean;
      }
      function isEmptyObject(obj: any) {
        return Object.getOwnPropertyNames(obj).length === 0;
      }
    
      const createNavigation = () => {
        let menu = JSON.parse(localStorage.getItem("mainMenu") || '{}');
        if(isEmptyObject(menu)) {
            checkMenuFunctions();
        }
        if (menu != null && menu.length > 0) {
          return {
            items: menu.map((item: any) => bindMenuItems(item))
          };
        }
      };
      
      interface MenuItem {
        name: string;
        url: string;
        icon: string;
        class: string;
        subMenus?: MenuItem[];
      }
      
      const bindMenuItems = (item: any): MenuItem => {
        var menuItem: MenuItem = {
          name: item.name,
          url: item.url,
          icon: item.icon,
          class: item.class
        };
      
        if (item.subMenus != null) {
          menuItem.subMenus = item.subMenus.map((node: any) => bindMenuItems(node));
        }
      
        return menuItem;
      };
      
      
      const SideNavBar: React.FC<SideBarProps> = ({ isVisible }) => {
        return isVisible ? (
                <Sidebar dynamicData={createNavigation} />   
        ) : null;
      };

    return (
        //  menuList?.length > 0 ?
        <div>
            <header className="default-header">
                {/*-- Header logo & title container --*/}
                <div className="default-header-logo-container">
                    {/* <a href={window.location.origin + "/dashboard"}>
                        <img src="https://spark.keellssuper.net/static/media/logo.e981cb26.jpg" alt="Keells Logo" />
                    </a>
                    <div id="main_menu_toggler_id">
                        <i id="main_menu_toggler_icon" className="fas fa-bars"></i>
                    </div> */}
                    <CSidebarToggler className="d-lg-none"/>
                    <CNavbarBrand>
                    <a href={window.location.origin + "/dashboard"}>
                        <img src="https://spark.keellssuper.net/static/media/logo.e981cb26.jpg" alt="Keells Logo" />
                    </a>
                    <div id="main_menu_toggler_id">
                        <i id="main_menu_toggler_icon" className="fas fa-bars"></i>
                    </div>
                    </CNavbarBrand>
                    
                </div>
                {/*-- Header user menu & granted application menu toggle container --*/}
                <div id="default_header_user_container" >
                    <img id="user_menu_toggler_id" src={userImg} className="user-menu-toggler" alt="User_toggler" />
                    {/* <div className="granted-app-list-toggler">
                        <i id="granted_app_list_toggler_id" className="fas fa-th-large"></i>
                    </div> */}
                </div>
                {/*-- User menu --*/}
                <div id="default_user_menu_id" ref={userMenuNode}>
                    <div className="user-menu-image-container">
                        <img src={userImg} alt="User" />
                    </div>
                    <div className="user-menu-full-name">{userSessionData.firstName}</div>
                    {/* <div className="user-menu-change-password-btn" onClick={() => changePassword()}>
                        <i className="fas fa-key" style={{ marginRight: "8px", fontSize: "12px" }} />Change Password</div> */}
                    <div className="user-menu-logout-btn" onClick={() => onLogout()}>
                        <i className="fas fa-power-off" style={{ marginRight: "8px", fontSize: "12px" }} />Logout</div>
                </div>
                {/*-- Granted application menu --*/}
                {/* <div id="default_granted_app_menu_id" ref={grantedAppMenuNode}>
                    {
                        userSessionData.grantedApps.map((application: any, index: any) =>
                        (
                            <div className="default-granted-app-menu-item" key={index} onClick={() => openApplication(application.baseURL)}>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    {application.applicationClientName}
                                </div>
                            </div>
                        )
                        )
                    }
                </div> */}
                {/*-- Main menu --*/}
                <div id="default_sidebar_id">
                  <SideNavBar isVisible={navMenuVisible} />
            </div>
            </header >        
        </div >
        //   :
        //  <LoadingScreen show={true}/>
    )
}

export default DefaultHeader;
import React from 'react';

interface iroute {
    id: any
    path: string
    name: string,
    component: any
}

const HomePage = React.lazy(() => import("./pages/homePage/HomePage"));
const FinalOrderDetails = React.lazy(() => import("./pages/FinalOrderDetails/FinalOrderDetails"));
const CustomerOrderDetails = React.lazy(() => import("./pages/CustomerOrderDetails/CustomerOrderDetails"));
const UserRegister = React.lazy(() => import("./pages/User/UserRegister"));
const CompanyRegister = React.lazy(() => import("./pages/Company/CompanyRegister"));
const ItemSetup = React.lazy(() => import("./pages/ItemSetup/ItemSetup"));


const routes: iroute[] = [
    { id: 0, path: "/home", name: "Home", component: HomePage },
    { id: 1, path: "/CustomerOrderDetails", name: "Customer Order Details", component: CustomerOrderDetails }, 
    { id: 2, path: "/FinalOrderDetails", name: "Order Dashboard", component: FinalOrderDetails }, 
    { id: 5, path: "/UserRegister", name: "User Registeration Portal", component: UserRegister }, 
    { id: 6, path: "/CompanyRegister", name: "Company Registeration Portal", component: CompanyRegister },
    { id: 8, path: "/ItemSetup", name: "Item Setup", component: ItemSetup },
]

export default routes;
import React from 'react';
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CNavItem,
  CBadge,
  CNavGroup,
  CSidebarToggler
} from '@coreui/react';

import './Sidebar.scss';
import { cilSpeedometer, cilPuzzle, cilLeaf, cilScrubber } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

interface MenuItem {
  name: string;
  url: string;
  icon: string;
  class: string;
  children?: MenuItem[];
}

interface SidebarProps {
  dynamicData: any;
}

const generateSidebarItem = (item: any): React.ReactNode => {
  if (item.subMenus && item.subMenus.length > 0) {
    return (
      <CNavGroup key={item.name} toggler={item.name} className='menu-level-1'>
        {/* <i className={item.icon}></i> */}
        {item.subMenus.map((child: any) => generateSidebarItem(child))}
      </CNavGroup>
    );
  }

  return (
    <CNavItem key={item.name} href={item.url || '#'} className='menu-level-2' >
      {/* <CIcon customClassName="nav-icon" icon={cilScrubber} color='#ffff' /> */}
      <i className={item.icon} style={{paddingRight: '15px'}} ></i>
      {item.name}
    </CNavItem>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ dynamicData }) => {
  const data = dynamicData();
  return (
    <CSidebar size='lg' className='sidebar' style={{marginLeft: '0px', position: 'relative'}}>
      <CSidebarNav>
        {data?.items.map((item: any) => generateSidebarItem(item))}
      </CSidebarNav>
    </CSidebar>
  );
};

export default Sidebar;
